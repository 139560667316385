body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

kbd {
  display: inline-block;
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  font-size: 11px;
  line-height: 1.5;
  color: #242729;
  text-shadow: 0 1px 0 #fff;
  background-color: #e4e6e8;
  border: 1px solid #9fa6ad;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(12, 13, 14, 0.15), inset 0 1px 0 0 #fff;
  overflow-wrap: break-word;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  position: sticky;
  top: 0;
}

main {
  flex: 1;
  margin: 0.5rem;

  @media print {
    margin: 0 !important;
  }
}

.cell {
  @media print {
    outline: none !important;
  }
}

.no-print {
  @media print {
    display: none !important;
  }
}

@import "./arrows";
