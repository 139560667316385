$arrowSize: 5px;
$arrowDiagonalGap: 5px;
$cellSize: 2rem;
/* For whatever reason, we need `-2` here */
$magicalFix: 2px;
$outlineWidth: 3px;

.arrow-north {
  width: 0;
  height: 0;
  border-left: $arrowSize solid transparent;
  border-right: $arrowSize solid transparent;

  border-bottom: $arrowSize solid black;

  position: absolute;
  top: -$arrowSize;
  left: calc(50% - #{$arrowSize});
}

.arrow-northeast {
  position: absolute;
  left: 50%;
  top: -$arrowDiagonalGap - $magicalFix;
  width: $arrowDiagonalGap;
  height: $arrowDiagonalGap;
  border-left: 1px solid black;
  border-top: 1px solid black;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-top: $arrowSize solid transparent;
    border-bottom: $arrowSize solid transparent;

    border-left: $arrowSize solid black;

    position: absolute;
    right: -$arrowSize;
    top: -$arrowSize;
  }
}

.arrow-east {
  width: 0;
  height: 0;
  border-top: $arrowSize solid transparent;
  border-bottom: $arrowSize solid transparent;

  border-left: $arrowSize solid black;

  position: absolute;
  right: -$arrowSize;
  top: calc(50% - #{$arrowSize});
}

.arrow-southeast {
  position: absolute;
  left: 50%;
  bottom: -$arrowDiagonalGap - $magicalFix;
  width: $arrowDiagonalGap;
  height: $arrowDiagonalGap;
  border-left: 1px solid black;
  border-bottom: 1px solid black;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-top: $arrowSize solid transparent;
    border-bottom: $arrowSize solid transparent;

    border-left: $arrowSize solid black;

    position: absolute;
    right: -$arrowSize;
    bottom: -$arrowSize;
  }
}

.arrow-south {
  width: 0;
  height: 0;
  border-left: $arrowSize solid transparent;
  border-right: $arrowSize solid transparent;

  border-top: $arrowSize solid black;

  position: absolute;
  bottom: -$arrowSize;
  left: calc(50% - #{$arrowSize});
}

.arrow-southwest {
  position: absolute;
  right: 50%;
  bottom: -$arrowDiagonalGap - $magicalFix;
  width: $arrowDiagonalGap;
  height: $arrowDiagonalGap;
  border-right: 1px solid black;
  border-bottom: 1px solid black;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-top: $arrowSize solid transparent;
    border-bottom: $arrowSize solid transparent;

    border-right: $arrowSize solid black;

    position: absolute;
    left: -$arrowSize;
    bottom: -$arrowSize;
  }
}

.arrow-west {
  width: 0;
  height: 0;
  border-top: $arrowSize solid transparent;
  border-bottom: $arrowSize solid transparent;

  border-right: $arrowSize solid black;

  position: absolute;
  left: -$arrowSize;
  top: calc(50% - #{$arrowSize});
}

.arrow-northwest {
  position: absolute;
  right: 50%;
  top: -$arrowDiagonalGap - $magicalFix;
  width: $arrowDiagonalGap;
  height: $arrowDiagonalGap;
  border-right: 1px solid black;
  border-top: 1px solid black;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-top: $arrowSize solid transparent;
    border-bottom: $arrowSize solid transparent;

    border-right: $arrowSize solid black;

    position: absolute;
    left: -$arrowSize;
    top: -$arrowSize;
  }
}

.arrow-westdown {
  position: absolute;
  top: 50%;
  left: -$arrowDiagonalGap - $magicalFix;
  width: $arrowDiagonalGap;
  height: $arrowDiagonalGap;
  border-left: 1px solid black;
  border-top: 1px solid black;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: $arrowSize solid transparent;
    border-right: $arrowSize solid transparent;

    border-top: $arrowSize solid black;

    position: absolute;
    left: -$arrowSize;
    bottom: -$arrowSize;
  }
}

.arrow-eastdown {
  position: absolute;
  top: 50%;
  right: -$arrowDiagonalGap - $magicalFix;
  width: $arrowDiagonalGap;
  height: $arrowDiagonalGap;
  border-right: 1px solid black;
  border-top: 1px solid black;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: $arrowSize solid transparent;
    border-right: $arrowSize solid transparent;

    border-top: $arrowSize solid black;

    position: absolute;
    right: -$arrowSize;
    bottom: -$arrowSize;
  }
}

.arrow-horizontal {
  position: absolute;
  top: 50%;
  right: -2 * $outlineWidth - $magicalFix;
  width: 0;
  height: 0;
  border-top: $arrowSize solid transparent;
  border-bottom: $arrowSize solid transparent;

  border-left: $arrowSize solid rgba(51, 51, 204, 0.5);
}

.arrow-vertical {
  position: absolute;
  left: 50%;
  bottom: -2 * $outlineWidth - $magicalFix;
  width: 0;
  height: 0;
  border-left: $arrowSize solid transparent;
  border-right: $arrowSize solid transparent;

  border-top: $arrowSize solid rgba(51, 51, 204, 0.5);
}
