body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

kbd {
  color: #242729;
  text-shadow: 0 1px #fff;
  overflow-wrap: break-word;
  background-color: #e4e6e8;
  border: 1px solid #9fa6ad;
  border-radius: 3px;
  margin: 0 .1em;
  padding: .1em .6em;
  font-size: 11px;
  line-height: 1.5;
  display: inline-block;
  box-shadow: 0 1px 1px #0c0d0e26, inset 0 1px #fff;
}

.app {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

header {
  position: sticky;
  top: 0;
}

main {
  flex: 1;
  margin: .5rem;
}

@media print {
  main {
    margin: 0 !important;
  }

  .cell {
    outline: none !important;
  }

  .no-print {
    display: none !important;
  }
}

.arrow-north {
  width: 0;
  height: 0;
  border-bottom: 5px solid #000;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  position: absolute;
  top: -5px;
  left: calc(50% - 5px);
}

.arrow-northeast {
  width: 5px;
  height: 5px;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  position: absolute;
  top: -7px;
  left: 50%;
}

.arrow-northeast:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid #000;
  position: absolute;
  top: -5px;
  right: -5px;
}

.arrow-east {
  width: 0;
  height: 0;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid #000;
  position: absolute;
  top: calc(50% - 5px);
  right: -5px;
}

.arrow-southeast {
  width: 5px;
  height: 5px;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  position: absolute;
  bottom: -7px;
  left: 50%;
}

.arrow-southeast:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid #000;
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.arrow-south {
  width: 0;
  height: 0;
  border-top: 5px solid #000;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  position: absolute;
  bottom: -5px;
  left: calc(50% - 5px);
}

.arrow-southwest {
  width: 5px;
  height: 5px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  position: absolute;
  bottom: -7px;
  right: 50%;
}

.arrow-southwest:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-right: 5px solid #000;
  position: absolute;
  bottom: -5px;
  left: -5px;
}

.arrow-west {
  width: 0;
  height: 0;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-right: 5px solid #000;
  position: absolute;
  top: calc(50% - 5px);
  left: -5px;
}

.arrow-northwest {
  width: 5px;
  height: 5px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  position: absolute;
  top: -7px;
  right: 50%;
}

.arrow-northwest:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-right: 5px solid #000;
  position: absolute;
  top: -5px;
  left: -5px;
}

.arrow-westdown {
  width: 5px;
  height: 5px;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  position: absolute;
  top: 50%;
  left: -7px;
}

.arrow-westdown:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid #000;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  position: absolute;
  bottom: -5px;
  left: -5px;
}

.arrow-eastdown {
  width: 5px;
  height: 5px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  position: absolute;
  top: 50%;
  right: -7px;
}

.arrow-eastdown:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid #000;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.arrow-horizontal {
  width: 0;
  height: 0;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid #3333cc80;
  position: absolute;
  top: 50%;
  right: -8px;
}

.arrow-vertical {
  width: 0;
  height: 0;
  border-top: 5px solid #3333cc80;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  position: absolute;
  bottom: -8px;
  left: 50%;
}

/*# sourceMappingURL=index.a495593b.css.map */
